import { NgModule } from "@angular/core";
import { BrainCardUrlPipe } from "app/pipes/brain-card-url.pipe";
import { CodeFormatPipe } from "./code-format.pipe";
import { CodeSplitPipe } from "./code-split.pipe";
import { CustomDatePipe } from "./custom-date.pipe";
import { UrlExtractionPipe } from "./url-extraction.pipe";
import { LocaleDatePipe } from "app/pipes/locale-date.pipe";

@NgModule({
  declarations: [BrainCardUrlPipe, CustomDatePipe, UrlExtractionPipe, CodeFormatPipe, CodeSplitPipe, LocaleDatePipe],
  imports: [],
  exports: [BrainCardUrlPipe, CustomDatePipe, UrlExtractionPipe, CodeFormatPipe, CodeSplitPipe, LocaleDatePipe],
})
export class PipesModule {}
