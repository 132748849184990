import { Injectable } from "@angular/core";
import { EmbeddingFile, EmbeddingFileStatus } from "app/app.model";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { expand, switchMap, takeWhile, timer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PollingService {
  constructor(private embeddingFileService: EmbeddingFileService) {}

  pollingEmbeddingFileStatus(embeddingFile: EmbeddingFile) {
    let attempt = 0;
    const baseDelay = 3000; // 3 seconds
    const maxDelay = 30000; // 30 seconds

    return timer(0).pipe(
      expand(() => {
        const delay = Math.min(baseDelay * Math.pow(2, attempt), maxDelay);
        attempt++;
        return timer(delay);
      }),
      switchMap(() => this.embeddingFileService.get(embeddingFile.id)),
      takeWhile(
        (res) => res?.data?.status === EmbeddingFileStatus.Processing,
        true
      )
    );
  }
}
