// LanguageService.ts
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private activeLangSubject = new BehaviorSubject<string>(""); // Initial value is an empty string
  activeLang$ = this.activeLangSubject.asObservable();

  constructor() {}

  setSelectedLanguage(lang: string) {
    this.activeLangSubject.next(lang);
  }

  getSelectedLanguage(): string {
    return this.activeLangSubject.value;
  }
}
