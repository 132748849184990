import { Pipe, PipeTransform } from "@angular/core";
import { CodeSplitModel } from "app/core/models/code-split.model";

@Pipe({
  name: "codeSplit",
})
export class CodeSplitPipe implements PipeTransform {
  transform(value: string): CodeSplitModel[] {
    const regex = /```([\s\S]*?)```/g;
    const matches: CodeSplitModel[] = [];
    let lastIndex = 0;

    value.replace(regex, (match, group, index) => {
      // Add non-matching part to the result
      const nonMatchingPart = value.substring(lastIndex, index);
      if (nonMatchingPart.trim() !== "") {
        matches.push({
          isCodeMatch: false,
          content: nonMatchingPart.trim(),
        });
      }

      // Add matching part to the result
      matches.push({
        isCodeMatch: true,
        content: group.trim(),
      });

      // Update lastIndex for the next iteration
      lastIndex = index + match.length;

      return match; // return the original match to keep it in the replaced string
    });

    // Add the remaining non-matching part if any
    const remainingNonMatchingPart = value.substring(lastIndex);
    if (remainingNonMatchingPart.trim() !== "") {
      matches.push({
        isCodeMatch: false,
        content: remainingNonMatchingPart.trim(),
      });
    }

    return matches;
  }
}
