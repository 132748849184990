<header
  class="flex items-center h-[56px] lg:h-[60px]"
  [ngStyle]="{ 'background-color': customizeColors ? customizeColorValues?.PrimaryColor : '#001E60' }"
  *transloco="let t; read: 'header'"
>
  <div class="px-3 lg:px-5 w-full m-auto">
    <div class="flex items-center" [ngClass]="!showOnlyLanguage || customizeColors ? 'justify-between' : 'justify-end'">
      <div *ngIf="customizeColors && isSharedBrain">
        <img src="./assets/images/mybrain-white-logo.svg" alt="" />
      </div>
      <div *ngIf="!showOnlyLanguage" class="logo-wrap flex items-center justify-center">
        <div *ngIf="isRouteStartsWithBrain()">
          <button (click)="openSidebar()" class="mr-2 sm:hidden">
            <img src="./assets/images/icons/menu.png" alt="Menu" />
          </button>
        </div>
        <a href="https://rezolve.com/assistant/" target="_blank" *ngIf="!userData; else loggedInLogo"
          ><img src="./assets/images/rezolve-logo-white.svg" alt="Rezolve"
        /></a>
        <ng-template #loggedInLogo>
          <a *ngIf="!this.isSharedBrain; else redirect" (click)="backToHomepage()" class="cursor-pointer"
            ><img src="./assets/images/rezolve-logo-white.svg" alt="Rezolve"
          /></a>
          <ng-template #redirect>
            <a href="https://rezolve.com/assistant/" target="_blank"
              ><img src="./assets/images/rezolve-logo-white.svg" alt="Rezolve"
            /></a>
          </ng-template>
        </ng-template>
      </div>
      <div>
        <div class="flex items-center">
          <!-- ... existing template ... -->
          <div class="flag-sec flex items-center">
            <div class="pl-4 pr-2 py-1.5 rounded-3xl justify-start items-center gap-2 inline-flex">
              <button mat-button [matMenuTriggerFor]="flagMenu" class="flex items-center gap-1">
                <div class="text-right text-white text-sm font-normal leading-tight">
                  {{ t("lang." + selectedLanguageFlag) }}
                </div>
                <div class="w-6 h-6 relative">
                  <img src="./assets/images/icons/menu-arrow.svg" alt="menu" />
                </div>
              </button>
            </div>
            <mat-menu #flagMenu="matMenu" xPosition="before" panelClass="flag-menu">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedLanguageCode">
                <mat-radio-button
                  *ngFor="let language of languages"
                  [value]="language.code"
                  class="flex w-full flag-high-light"
                  (click)="changeLanguage(language.code)"
                >
                  <div class="text-black text-sm font-normal inline-flex cursor-pointer items-center">
                    <!-- <img
                      src="./assets/images/flags/{{ language.flag }}.svg"
                      alt="{{ language.label }}"
                      class="mr-2 w-6 h-4"
                    /> -->
                    {{ t("lang." + language.flag) }}
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </mat-menu>
          </div>

          <!-- ... existing template ... -->

          <div class="user-sec flex items-center ml-3" *ngIf="userData && !showOnlyLanguage && !isSharedBrain">
            <button mat-button [matMenuTriggerFor]="userMenu">
              <div class="username">
                {{ iconName }}
              </div>
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before" class="user-menu">
              <div class="px-4">
                <div class="flex max-w-[320px] py-2 items-center">
                  <div class="w-10 lg:w-10 h-10 lg:h-10 flex items-center">
                    <span class="username bg-gray-c border border-primary">
                      {{ iconName }}
                    </span>
                  </div>
                  <div class="ml-2 overflow-hidden">
                    <div class="text-lg text-black-dark1 font-medium" *ngIf="userData.firstName && userData.lastName">
                      {{ userData.firstName }}&nbsp;{{ userData.lastName }}
                    </div>
                    <div
                      class="text-base text-light-black overflow-hidden text-ellipsis whitespace-nowrap"
                      *ngIf="userData"
                      [matTooltip]="userData.email"
                    >
                      {{ userData.email }}
                    </div>
                  </div>
                </div>
              </div>
              <button mat-menu-item (click)="navigateToAccountDetails()">
                <div class="flex text-f13 items-center py-1">
                  <i class="mr-2">
                    <img src="./assets/images/icons/user.svg" alt="" class="w-[18px] h-[18px]" />
                  </i>
                  <span class="text-secondary font-semibold">
                    {{ t("accountDetails") }}
                  </span>
                </div>
              </button>
              <button mat-menu-item *ngIf="isMobileDevice() && !checkIfInstalled()" (click)="triggerPwa()">
                <div class="flex text-f13 items-center py-1">
                  <i class="mr-2">
                    <img src="./assets/images/get_app.png" alt="" class="w-[18px] h-[18px]" />
                  </i>
                  <span class="text-secondary font-semibold">
                    {{ "pwa.installAsApp" | transloco }}
                  </span>
                </div>
              </button>
              <button mat-menu-item *ngIf="showSettings" (click)="openNativeSettings()">
                <div class="flex text-f13 items-center py-1">
                  <i class="mr-2">
                    <img src="./assets/images/settings.svg" alt="" class="w-[18px] h-[18px]" />
                  </i>
                  <span class="text-secondary font-semibold">
                    {{ "leftSidebar.settings" | transloco }}
                  </span>
                </div>
              </button>
              <button mat-menu-item (click)="redirectToAffiliates()">
                <div class="flex text-f13 items-center py-1 rounded-lg">
                  <i class="mr-2">
                    <img src="./assets/images/refer_a_friend.svg" alt="" class="w-[18px] h-[18px]" />
                  </i>
                  <span class="text-secondary font-semibold">{{ "referAFriend" | transloco }}</span>
                </div>
              </button>
              <div class="bg-white-smoke w-full">
                <button mat-menu-item (click)="logout()">
                  <div class="flex text-f13 font-semibold items-center py-1">
                    {{ t("logout") }}
                    <i class="ml-2">
                      <img src="./assets/images/icons/logout.svg" alt="" class="w-[18px] h-[18px]" />
                    </i>
                  </div>
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
