import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({ providedIn: "root" })
export class UpdateTitle extends TitleStrategy {
  defaultTitle = "Brain Assistant";

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly title: Title,
  ) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title !== undefined) {
      this.translocoService.selectTranslate(title).subscribe((translatedTitle) => {
        if (translatedTitle) {
          this.title.setTitle(`${this.defaultTitle} - ${translatedTitle}`);
        } else {
          this.title.setTitle(this.defaultTitle);
        }
      });
    }
  }

  translatePageTitle(titleKey: string) {
    this.translocoService.selectTranslate(`title.${titleKey}`).subscribe((translatedTitle) => {
      if (translatedTitle) {
        this.title.setTitle(`${this.defaultTitle} - ${translatedTitle}`);
      } else {
        this.title.setTitle(this.defaultTitle);
      }
    });
  }
}
