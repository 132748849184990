<ng-container *transloco="let t; read: 'pwa'">
  <div class="bg-white h-2/4 shadow flex-col justify-start items-start inline-flex" *ngIf="data.mobileType === 'ios'">
    <div class="self-stretch p-4 bg-slate-400 bg-opacity-10 justify-start items-start gap-3 inline-flex">
      <div class="grow shrink basis-0 text-lg font-semibold leading-7 tracking-tight">{{ t("iosTitle") }}</div>
      <div class="w-6 h-6 relative" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="self-stretch h-[1019px] px-4 pt-4 pb-16 flex-col justify-start items-start gap-3 flex">
      <div class="self-stretch h-20 flex-col justify-start items-start gap-1 flex">
        <div
          class="w-7 px-2 py-1 bg-cyan-300 bg-opacity-20 rounded-[32px] justify-start items-center gap-2 inline-flex"
        >
          <div class="grow shrink basis-0 text-center text-sky-700 text-sm font-semibold leading-tight tracking-tight">
            1
          </div>
        </div>
        <div class="self-stretch text-zinc-900 text-base font-normal font-['Noto Sans'] leading-normal tracking-tight">
          {{ t("step1") }}
        </div>
      </div>
      <img class="w-[300px] h-[170px]" src="./assets/images/ios-menu-btn.png" />
      <div class="self-stretch h-[104px] pt-6 flex-col justify-start items-start gap-1 flex">
        <div
          class="w-7 px-2 py-1 bg-cyan-300 bg-opacity-20 rounded-[32px] justify-start items-center gap-2 inline-flex"
        >
          <div class="grow shrink basis-0 text-center text-sky-700 text-sm font-semibold leading-tight tracking-tight">
            2
          </div>
        </div>
        <div class="self-stretch text-zinc-900 text-base font-normal font-['Noto Sans'] leading-normal tracking-tight">
          {{ t("step2") }}
        </div>
      </div>
      <img class="w-[300px] h-[549px]" src="./assets/images/ios-add-btn.png" />
    </div>
  </div>
</ng-container>
