export class CharacterFormatter {
  private isInOrderedList = false;
  private isInUnorderedList = false;
  private formattedResponse = "";
  private previousChar = "";

  reset() {
    this.isInOrderedList = false;
    this.isInUnorderedList = false;
    this.formattedResponse = "";
    this.previousChar = "";
  }

  formatCharacter(char: string) {
    this.formattedResponse += char;

    return this.formattedResponse;
  }
}
