<div class="flex flex-col p-4">
  <h2 class="dialog-head">
    <div class="dialog-head flex justify-between items-center" *transloco="let t; read: 'leftSidebar'">
      <div class="text-lg font-medium text-black-dark">
        {{ title }}
      </div>
    </div>
  </h2>
  <div class="mat-typography">
    <div class="w-full px-0 md:w-[420px]">
      <div class="text-base text-black-lighter font-base" [innerHTML]="confirmBodyTxt"></div>
    </div>
  </div>
  <div>
    <mat-dialog-actions align="end" class="!border-0 !p-0 !pt-4">
      <button mat-button mat-dialog-close class="px-0" [mat-dialog-close]="ConfirmDialogOption.cancel">
        {{ cancelButtonTxt }}
      </button>
      <button mat-stroked-button color="warn" [mat-dialog-close]="ConfirmDialogOption.ok">{{ okButtonTxt }}</button>
    </mat-dialog-actions>
  </div>
</div>
