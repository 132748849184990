import { Injectable } from "@angular/core";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { environment } from "app/environments/environment";
import { Brain, SharedBrainData } from "app/pages/dashboard/dashboard.model";
import { GenericBAResponse } from "app/app.model";

@Injectable({
  providedIn: "any",
})
export class EmbeddingProjectsService {
  constructor(private http: HttpClientService) {}

  get(id: string, isAuth = true) {
    return this.http.get<Brain>(`${environment.dotNetBaseUrl}/api/embeddingProject/${id}`, { isAuth: isAuth });
  }

  update(brain: Brain) {
    return this.http.put<Brain>(`${environment.dotNetBaseUrl}/api/embeddingProject`, brain);
  }

  create(brain: Brain) {
    return this.http.post<Brain>(`${environment.dotNetBaseUrl}/api/embeddingProject`, brain);
  }

  delete(id: string, embeddingFilesIds: string[] = []) {
    return this.http.delete<boolean>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/delete?id=${id}`,
      embeddingFilesIds,
    );
  }

  getUserBrains(orderBy: string, searchValue: string, brainType: string, page = 0, pageSize = 100000) {
    return this.http.get<GenericBAResponse<Brain>>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/getBy?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&searchValue=${searchValue}&brainType=${brainType}`,
    );
  }

  getSharedBrainData(brainKey: string) {
    return this.http.get<SharedBrainData>(`${environment.dotNetBaseUrl}/api/shared/data?brainKey=${brainKey}`, {
      isAuth: false,
    });
  }

  getSharedBrainKey(projectId: string) {
    return this.http.get<{ key: string }>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/shared/key?projectId=${projectId}`,
    );
  }
}
