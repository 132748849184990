import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { LS_REFRESH_TOKEN, LS_TOKEN } from 'app/const/app-constant';
import { getLocalStorageInterface, isLocalStorageInterfaceDefined } from 'app/core/modules/mobile-interfaces/app-local-storage-interface';
import { environment } from 'app/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  refreshToken(): Observable<boolean> {
    const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN);
    if (!refreshToken) {
      return throwError(() => new Error("No refresh token available"));
    }

    return this.httpClient
      .post<{ accessToken: string; refreshToken?: string }>(
        `${environment.dotNetBaseUrl}/api/Authentication/refresh`,
        { refreshToken }
      )
      .pipe(
        tap((response) => {
          localStorage.setItem(LS_TOKEN, response.accessToken);
          if (response.refreshToken) {
            localStorage.setItem(LS_REFRESH_TOKEN, response.refreshToken);
          }
        }),
        map(() => true),
        catchError((error: HttpErrorResponse) => {
          console.error("Refresh token error:", error);
          return throwError(() => error);
        })
      );
  }

  logout() {
    localStorage.removeItem(LS_TOKEN);
    AppStore.isLoggedIn$.next(false);
    AppStore.userData$.next(null);
    AppStore.userDataLimits$.next(null);
    if (isLocalStorageInterfaceDefined()) {
      getLocalStorageInterface().onLogout();
    }
    AppStore.sidebarActive$.next(false);
    AppStore.footerActive$.next(true);
    AppStore.selectedBrainId$.next(null);
    AppStore.brainProjects$.next([]);
    AppStore.selectedBrain$.next(null);

  }
}
