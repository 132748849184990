import { AppStore } from "app/app-store.service";
import { UserData, UserDataLimits } from "app/pages/home/home.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "any",
})
export class UserDataLimitsService {
  checkUserLimit(userData: UserData) {
    if (userData) {
      const isContentExceeded = userData.contentFileCount >= userData.contentFileLimit;
      const isQuestionExceeded = userData.questionMonthlyCount >= userData.questionMonthlyCredits;
      const userDataLimits = {
        isContentExceeded: isContentExceeded,
        isQuestionExceeded: isQuestionExceeded,
        isLimitVisible: isContentExceeded || isQuestionExceeded,
      } as UserDataLimits;
      AppStore.userDataLimits$.next(userDataLimits);
    }
  }
}
