import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customDate",
})
export class CustomDatePipe implements PipeTransform {
  transform(value: Date | string): string {
    if (typeof value === "string") {
      value = new Date(value);
    }

    const todayDate = new Date().getDate();
    const todayMonth = new Date().getMonth();
    const todayYear = new Date().getFullYear();

    const day = value.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[value.getMonth()];
    const year = value.getFullYear();
    const hours = value.getHours();
    const minutes = value.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const getOrdinalSuffix = this.getOrdinalSuffix(day);

    const formattedTime = `${this.formatTime(hours, minutes)} ${ampm}`;

    if (todayDate === day && todayMonth === value.getMonth() && todayYear === year) {
      return formattedTime;
    }
    const formattedDate = `${getOrdinalSuffix} ${month} ${year} ${formattedTime}`;

    return formattedDate;
  }

  private getOrdinalSuffix(n: number): string {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  private formatTime(hours: number, minutes: number): string {
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }
}
