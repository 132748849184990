import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "urlExtraction",
})
export class UrlExtractionPipe implements PipeTransform {
  transform(fullUrl: string): unknown {
    // Creating a URL object
    const url = new URL(fullUrl);
    // Getting the base URL
    const baseUrl = `${url.host}`;
    return baseUrl;
  }
}
