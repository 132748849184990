import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "app/language.service";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "localeDate",
  pure: false,
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(value: any, format?: string): any {
    const language = this.languageService.getSelectedLanguage();
    const dateFormat = this.getDateFormat(language);
    const datePipe = new DatePipe("en-gb");
    return datePipe.transform(value, dateFormat);
  }

  getDateFormat(language: string): string {
    switch (language) {
      case "ar_ar":
        return "dd/MM/yyyy, HH:MM a";
      case "de_de":
        return "dd.MM.yyyy, HH:MM a";
      case "en_gb":
        return "MM/dd/yyyy, HH:mm a";
      case "es_es":
        return "dd/MM/yyyy, HH:MM a";
      case "fr_fr":
        return "dd/MM/yyyy, HH:MM a";
      case "ko_ko":
        return "yyyy.MM.dd, HH:MM a";
      case "zh_cn":
        return "yyyy-MM-dd, HH:MM a";
      default:
        return "MM/dd/yyyy, hh:mm a";
    }
  }
}
