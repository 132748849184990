/* eslint-disable max-lines */
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { DEFAULT_FOLLOWUP_QUESTIONS } from "app/const/app-constant";
import { generateRandomString } from "app/core/functions/helper-functions";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { MyBrainPlans, UserData } from "app/pages/home/home.model";
import { map } from "rxjs";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { ProjectTypes } from "app/enums/project-types.enum";

@Injectable({
  providedIn: "any",
})
export class AddMyBrainContentService {
  constructor(
    private translocoService: TranslocoService,
    private embeddingProjectsService: EmbeddingProjectsService,
  ) {}

  createBrain(userData: UserData, brainType: ProjectTypes, projectName?: string) {
    const translatedFollowUpQuestion = DEFAULT_FOLLOWUP_QUESTIONS.map((question) => {
      return this.translocoService.translate(question);
    });

    //TODO: move sensitive data like api, embed openAi keys and BL to backend
    let body = {
      projectName: projectName,
      useCaseAudience: userData?.aboutIndustry,
      useCaseIndustry: userData?.aboutEndUser,
      pineconeIndexName: "index-my-brain-1", // [TODO] get from database
      embedKey: generateRandomString(30),
      apiKey: generateRandomString(30),
      openAISecretKey: userData.openAISecretKey,
      followUpQuestions: translatedFollowUpQuestion,
      creationDate: new Date().toISOString(),
      projectType: brainType,
    } as Brain;

    //TODO: this BL can go to BE
    if (userData.planLevel === MyBrainPlans.enthusiast) {
      body = {
        ...body,
        settingLinkSync: true,
        settingLinkSyncFrequency: "monthly",
      } as Brain;
    } else if (userData.planLevel === MyBrainPlans.powerUser || userData.planLevel === MyBrainPlans.professional) {
      body = {
        ...body,
        settingLinkSync: true,
        settingLinkSyncFrequency: "weekly",
      } as Brain;
    }

    return this.embeddingProjectsService.create(body).pipe(
      map((resp) => {
        if (resp && resp.isSuccess && resp.data) {
          const brain = resp.data as Brain;
          const brains = AppStore.brainProjects$.value;

          AppStore.selectedBrain$.next(brain);
          AppStore.brainProjects$.next([brain, ...brains]);
          return resp.data as Brain;
        }
        return null;
      }),
    );
  }
}
