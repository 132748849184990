import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { HOME_ROUTE, LS_TOKEN } from "app/const/app-constant";
import { isTokenValid } from "app/core/functions/helper-functions";
import { AuthService } from "app/services/auth.service";
import { finalize, of, switchMap } from "rxjs";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const token = localStorage.getItem(LS_TOKEN);
  const splashScreen = document.getElementById('splash-screen');

  const showSplashScreen = () => {
    if (splashScreen) splashScreen.style.display = 'flex';
  };

  const hideSplashScreen = () => {
    if (splashScreen) splashScreen.style.display = 'none';
  };

  if (token && isTokenValid(token)) {
    return true;
  } else if (token) {
    showSplashScreen();
    return authService.refreshToken().pipe(
      switchMap((refreshed) => {
        if (refreshed) {
          
          return of(true); 
        } else {
          authService.logout(); 
          return of(router.createUrlTree([HOME_ROUTE], { queryParams: { returnUrl: state.url } }));
        }
      }),
      finalize(() => hideSplashScreen())
    );
  } else {
    return of(router.createUrlTree([HOME_ROUTE], { queryParams: { returnUrl: state.url } }));
  }
};