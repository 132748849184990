interface LocalStorageInterface {
    onAccessTokenDataSet(loginToken: String) : void;
    onUserIdDataSet(userId: String) : void;
    onLocalStorageDataSet(loginToken: String, userId: String) : void;
    onRefreshTokenDataSet(refreshToken: string): void;
    onLogout(): void;
}

export function isLocalStorageInterfaceDefined() {
    return typeof mobileappStorageInterface !== 'undefined';
}

export function getLocalStorageInterface() {
    return mobileappStorageInterface;
}

declare var mobileappStorageInterface: LocalStorageInterface; // interface is defined by the mobile apps

