import { CanActivateFn } from "@angular/router";
import { LS_TOKEN, SSO_TOKEN } from "app/const/app-constant";

//This is used in case you want to pass a session for keeping the login active
// over different interfaces (mobile <> web)
export const tokenGuard: CanActivateFn = (route) => {
  const loginToken = route.queryParams[SSO_TOKEN];
  if (loginToken) {
    localStorage.setItem(LS_TOKEN, loginToken);
  }
  return true;
};
