<div class="container mx-auto" *transloco="let t; read: 'noBrainPage'">
  <div class="flex flex-row items-center justify-center" [ngClass]="isEmbeddedChat ? 'flex-row' : 'flex-col'">
    <img src="assets/images/no-brain.svg" />
    <div class="flex flex-col gap-4" [dir]="activeLang === 'ar_ar' ? 'rtl' : 'ltr'">
      <span class="text-4xl">{{ t("brainNotExisting") }}</span>
      <span class="text-lg text-rezolve-light-gray">{{ t("brainNoAccess") }}</span>
      <a *ngIf="isEmbeddedChat" href="https://rezolve.com/assistant/" class="!text-accent w-fit cursor-pointer pt-4">{{
        t("backToHomepage")
        }}</a>
    </div>
  </div>
</div>
