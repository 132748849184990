<!-- eslint-disable max-len -->
<div
  class="flex flex-col w-full border-t border-t-[#f0f0f0] px-2"
  [ngClass]="{ error: embeddingFile.errorMessage }"
  *transloco="let t; read: 'uploadedContentTranslation'"
>
  <div class="w-full flex flex-row items-center min-h-[50px] gap-3 justify-between">
    <div class="flex flex-row gap-3 items-center overflow-ellipsis-box">
      <div class="icon flex" [ngSwitch]="embeddingFile.contentType">
        <img
          class="icon"
          src="./assets/images/icons/brain-content/globe.svg"
          *ngSwitchCase="BrainContentType.Website"
        />
        <img
          class="icon"
          src="./assets/images/icons/brain-content/web-page-single.svg"
          *ngSwitchCase="BrainContentType.Link"
        />
        <img
          class="icon"
          [src]="getFileIconPath(embeddingFile.fileExtension)"
          *ngSwitchDefault
        />
      </div>
      <span
        class="name text-black-dark1 text-sm font-semibold overflow-hidden text-ellipsis cursor-pointer"
        matTooltip="{{ embeddingFile.metadata?.title }}"
        >{{ embeddingFile.metadata?.title }}</span
      >
    </div>

    <div class="flex flex-row gap-3 items-center">
      <div class="status" [ngSwitch]="embeddingFile.status">
        <div
          class="bg-warn-light2 text-warn1 py-1.5 px-3 font-semibold flex flex-row items-center rounded-[32px] gap-1"
          *ngSwitchCase="EmbeddingFileStatus.Failed"
        >
          <img src="./assets/images/icons/error-outline.svg" class="w-5 h-5" />
          <span class="text-sm">{{ t("error") }}</span>
        </div>
        <div
          class="min-w-[155px] bg-[#8e78b529] text-[#554176] py-1.5 px-3 font-semibold flex flex-row items-center rounded-[32px] gap-1"
          *ngSwitchCase="EmbeddingFileStatus.Processing"
        >
          <mat-spinner [diameter]="15"></mat-spinner>
          <span class="text-sm">{{ t("doingAIMagic") }}</span>
        </div>

        <div
          class="py-1.5 px-3 font-semibold flex flex-row items-center gap-1"
          *ngSwitchCase="EmbeddingFileStatus.Indexing"
        >
          <mat-spinner [diameter]="15"></mat-spinner>
          <span class="text-sm">{{ t("indexing") }}</span>
        </div>

        <div
          class="py-1.5 px-3 font-semibold flex flex-row items-center gap-1"
          *ngSwitchCase="EmbeddingFileStatus.Uploading"
        >
          <mat-spinner [diameter]="15"></mat-spinner>
          <span class="text-sm">{{ t("uploading") }}</span>
        </div>

        <div
          class="min-w-[125px] bg-success-light text-[#2E7D32] py-1.5 px-3 font-semibold flex flex-row items-center rounded-[32px] gap-1"
          *ngSwitchCase="EmbeddingFileStatus.Completed"
        >
          <img src="./assets/images/icons/check-icon.svg" class="w-5 h-5" />
          <span class="text-sm">{{ t("completed") }}</span>
        </div>
      </div>

      <div *ngIf="embeddingFile && embeddingFile.id" class="action icon cursor-pointer" (click)="deleteContentData()">
        <img class="icon" src="./assets/images/icons/delete-icon-new-purple.svg" />
      </div>
    </div>
  </div>
  <div *ngIf="embeddingFile?.errorMessage" class="flex flex-col w-full pb-2">
    <span class="text-xs">{{ t("errorMessage") }}:</span>
    <span class="text-sm text-black-dark1 font-semibold">{{ t(embeddingFile.errorMessage!) || t("genericErrorMessage") }}</span>
  </div>
</div>
