interface WebAppInterface {
    onWebAppInitialized() : void;
}

declare global {
    interface Window {
        webkit?: {
            messageHandlers?: any;
        }
    }
}

export function isAndroidApp() {
    return isMobileInterfaceDefined();
}

export function isAppleApp() {
    return window.webkit?.messageHandlers ?? false;
}

export function isMobileInterfaceDefined() {
    return typeof mobileappInitializationInterface !== "undefined";
}

export function getMobileappInitializationInterface() {
    return mobileappInitializationInterface;
}
  
declare let mobileappInitializationInterface: WebAppInterface; // interface is defined by the mobile apps
