import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValidationMessageComponent } from "./validation-message.component";
import { ValidationMessageService } from "./validation-message.service";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule],
  declarations: [ValidationMessageComponent],
  providers: [ValidationMessageService],
  exports: [ValidationMessageComponent, ReactiveFormsModule],
})
export class ValidationMessageModule {}
