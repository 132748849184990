/* eslint-disable @typescript-eslint/no-explicit-any */
export function removeSpaceFromKey(val: Record<string, any> | any[]) {
  if (!val) {
    return val;
  }
  if (Array.isArray(val)) {
    const newArray: any[] = [];
    (val as any[]).forEach((k, i) => {
      newArray[i] = removeSpaceFromKey(k);
    });
    return newArray;
  } else if (typeof val === "object") {
    const newObj: Record<string, any> = {};
    Object.keys(val).forEach(key => {
      const newKey = key.split(" ").reduce((prev, current, idx) => {
        if (idx !== 0) {
          return prev + current.charAt(0).toLocaleUpperCase() + current.slice(1);
        }
        return current;
      }, "");
      newObj[newKey] = val[key];
      if (Array.isArray(val[key])) {
        val[key].forEach((k: any, i: number) => {
          newObj[newKey][i] = removeSpaceFromKey(k);
        });
      } else if (typeof val[key] === "object") {
        newObj[newKey] = removeSpaceFromKey(val[key]);
      }
    });
    return newObj;
  }
  return val;
}