/* eslint-disable max-lines */
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BrainContentType, EmbeddingFile, EmbeddingFileStatus, Metadata, WebAddressPostResp } from "app/app.model";
import { AddedBrainContentComponent } from "app/core/components/added-brain-content/added-brain-content.component";
import { CustomValidator } from "app/core/modules/validation-message/custom-validator";
import { ValidationMessageModule } from "app/core/modules/validation-message/validation-message.module";
import { BrainLimitDirective } from "app/directives/brain-limit.directive";
import { environment } from "app/environments/environment";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { MyBrainPlans, UserData, UserDataLimits } from "app/pages/home/home.model";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { UsersService } from "app/services/users.service";
import { map, mergeMap, of } from "rxjs";
import { BaseHttpComponent } from "../base-http/base-http.component";
import { PollingService } from "app/core/services/polling.service";

@Component({
  selector: "app-web-page-upload",
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormsModule,
    ValidationMessageModule,
    MatProgressSpinnerModule,
    BrainLimitDirective,
    AddedBrainContentComponent,
    MatExpansionModule,
  ],
  templateUrl: "./web-page-upload.component.html",
  styleUrls: ["./web-page-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WebPageUploadComponent extends BaseHttpComponent implements OnInit {
  constructor(
    private embeddingFileService: EmbeddingFileService,
    private translocoService: TranslocoService,
    private userService: UsersService,
    private pollingService: PollingService,
  ) {
    super();
  }

  @Input() fileUploading = false;
  @Input() webCount = 0;
  @Output() webBrainContents = new EventEmitter<EmbeddingFile[]>();
  @Output() isUploading = new EventEmitter<boolean>();
  @Output() addedWebCount = new EventEmitter<number>();

  // eslint-disable-next-line quotes
  webPageUrl = new FormControl<string>("", { validators: [CustomValidator.url()] });
  addAllPages = false;
  errorMessage = "";
  contents: EmbeddingFile[] = [];
  completedContents = 0;
  selectedBrain: Brain | null = null;
  entireWebsiteCrawlInProgress = false;
  userDataLimits!: UserDataLimits;
  userData?: UserData;
  uploading = false;

  ngOnInit(): void {
    this.subs$.add(
      AppStore.selectedBrain$.subscribe((brain) => {
        this.selectedBrain = brain;
        this.cdr.markForCheck();
      }),
    );

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        if (resp) {
          this.userDataLimits = resp as UserDataLimits;
        }
      }),
    );

    this.subs$.add(
      AppStore.userData$.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.cdr.markForCheck();
        }
      }),
    );
  }

  async validateUrl() {
    this.errorMessage = "";
    if (this.webPageUrl.invalid || !this.webPageUrl.value) {
      return;
    }
    const brainContent = [...this.contents, ...AppStore.brainContents$.value];
    if (brainContent.find((content) => content.webpage === this.webPageUrl.value)) {
      this.errorMessage = this.transloco.translate("addMyBrainContent.websiteAdded");
      return;
    }

    if (this.addAllPages) {
      this.uploadSite();
    } else {
      this.uploadSinglePage();
    }
  }

  uploadSite() {
    AppStore.isIndexing$.next(true);
    this.isUploading.emit(true);
    this.entireWebsiteCrawlInProgress = true;

    const website = {
      projectId: this.selectedBrain?.id,
      status: EmbeddingFileStatus.Indexing,
      contentType: BrainContentType.Website,
      webpage: this.webPageUrl.value,
      metadata: {
        title: this.webPageUrl.value,
      },
      failed: false,
    } as EmbeddingFile;
    this.contents.push(website);
    this.addedWebCount.emit(1);
    AppStore.addedBrainContents$.next(this.contents);
    this.completedContents = this.contents.length;
    this.cdr.markForCheck();
    this.isUploading.emit(true);

    this.embeddingFileService.addWebsite(website).subscribe((res) => {
      if (res && res.isSuccess && res.data) {
        const currentWebSite = res.data as EmbeddingFile;
        const currentContentIndex = this.contents.findIndex(
          (x) => x.webpage?.toLocaleLowerCase() === currentWebSite.webpage?.toLocaleLowerCase(),
        );
        if (currentContentIndex > -1) {
          this.contents[currentContentIndex] = currentWebSite;
        } else {
          this.contents = [...this.contents, currentWebSite];
        }
        AppStore.addedBrainContents$.next(this.contents);
        this.webBrainContents.emit(this.contents);
        this.isUploading.emit(false);
        this.cdr.markForCheck();
      } else {
        if (!(res.isSuccess && res.data) && res.message && res.message.length) {
          this.errorMsg(this.translocoService.translate("validation." + res.message[0]));
          this.contents = this.contents.filter((c) => c.webpage !== website.webpage);
          AppStore.addedBrainContents$.next(this.contents);
          this.webBrainContents.emit(this.contents);
          this.addedWebCount.emit(-1);
          this.completedContents = this.contents.length;
          this.isUploading.emit(false);
        }
      }
    });
  }
  uploadSinglePage() {
    this.isUploading.emit(true);
    this.uploading = true;
    const webPageContent = {
      contentType: BrainContentType.Link,
      webpage: this.webPageUrl.value,
      status: EmbeddingFileStatus.Processing,
      fileName: this.webPageUrl.value,
      metadata: {
        title: this.webPageUrl.value,
      },
      failed: false,
    } as EmbeddingFile;
    this.contents.push(webPageContent);
    this.addedWebCount.emit(1);
    AppStore.addedBrainContents$.next(this.contents);
    this.cdr.markForCheck();
    let currentEmbeddingFile = webPageContent;
    this.embeddingFileService
      .addWebpage({
        projectId: this.selectedBrain?.id,
        status: EmbeddingFileStatus.Processing,
        webpage: webPageContent.webpage,
        contentType: BrainContentType.Link,
        failed: false,
      } as EmbeddingFile)
      .pipe(
        mergeMap((resp) => {
          this.webPageUrl.setValue("");
          if (!(resp.isSuccess && resp.data)) {
            this.errorMsg(this.translocoService.translate("validation.invalidWebpage"));
            this.contents = this.contents.filter((c) => c.webpage !== webPageContent.webpage);
            AppStore.addedBrainContents$.next(this.contents);
            this.webBrainContents.emit(this.contents);
            this.addedWebCount.emit(-1);
            this.completedContents = this.contents.length;
            this.uploading = false;
            this.isUploading.emit(false);
            this.cdr.markForCheck();
            return of(null);
          }
          this.uploading = false;
          this.isUploading.emit(false);
          this.updateCurrentPage(resp.data);
          currentEmbeddingFile = resp.data;
          this.completedContents = this.contents.length;
          this.webBrainContents.emit(this.contents);
          return this.userService.get();
        }),
      )
      .pipe(
        mergeMap((resp) => {
          if (resp && resp.isSuccess && resp.data) {
            AppStore.userData$.next(resp.data);
            this.userData = resp.data;
            this.cdr.markForCheck();
            return this.embeddingFileService.ingestFile(currentEmbeddingFile);
          } else {
            return of(null);
          }
        }),
      )
      .pipe(
        mergeMap((resp) => {
          if (resp && resp.isSuccess && resp.data) {
            this.updateCurrentPage(resp.data);
            this.completedContents = this.contents.length;
            this.webBrainContents.emit(this.contents);
            return this.pollingService.pollingEmbeddingFileStatus(resp.data).pipe(
              mergeMap((pollingResult) => {
                if (pollingResult.isSuccess && pollingResult.data) {
                  return this.userService.get().pipe(
                    map((userResponse) => ({
                      pollingResult,
                      userResponse,
                    })),
                  );
                }
                return of({
                  pollingResult,
                  userResponse: null,
                });
              }),
            );
          }
          return of(null);
        }),
      )
      .subscribe({
        next: (result) => {
          if (result && result.pollingResult?.isSuccess && result.pollingResult.data) {
            this.updateCurrentPage(result.pollingResult.data);
            this.completedContents = this.contents.length;
            this.webBrainContents.emit(this.contents);
          }
        },
        error: (err) => {
          console.error(err);
          this.isUploading.emit(false);
          this.uploading = false;
          this.cdr.markForCheck();
        },
        complete: () => {
          this.isUploading.emit(false);
          this.uploading = false;
          this.cdr.markForCheck();
        },
      });
  }

  updateCurrentPage(embeddingFile: EmbeddingFile) {
    const currentContentIndex = this.contents.findIndex(
      (x) => x.webpage?.toLocaleLowerCase() === embeddingFile.webpage?.toLocaleLowerCase(),
    );

    if (currentContentIndex > -1) {
      this.contents[currentContentIndex] = embeddingFile;
      AppStore.addedBrainContents$.next(this.contents);
      this.cdr.markForCheck();
    }
  }

  deleteContent(currentContent: EmbeddingFile) {
    const content = this.contents.find((x) => x.webpage === currentContent.webpage);
    if (!content) {
      return;
    }

    this.embeddingFileService
      .delete(content.id)
      .pipe(
        mergeMap((res) => {
          if (res && res.isSuccess && res.data) {
            this.contents = this.contents.filter((c) => c.id !== content.id);
            AppStore.addedBrainContents$.next(this.contents);
            this.webBrainContents.emit(this.contents);
            this.addedWebCount.emit(-1);
            this.cdr.markForCheck();
            return this.userService.get();
          }

          return of(null);
        }),
      )
      .subscribe((result) => {
        if (result && result.isSuccess && result.data) {
          AppStore.userData$.next(result.data);
        }
      });
  }

  protected readonly MyBrainPlans = MyBrainPlans;
}
