/**
 * Cleans markdown special characters from a string
 * @param text The input string containing markdown
 * @returns The cleaned string without markdown special characters
 */
export function cleanupMarkdown(text: string): string {
  // Regular expression to match common markdown special characters
  const markdownRegex = /[*_`#[\]()~>]/g;

  // Replace markdown special characters with an empty string
  return text.replace(markdownRegex, "");
}

