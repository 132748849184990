import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, NO_ERRORS_SCHEMA } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { TranslocoDirective } from "@ngneat/transloco";

@Component({
  selector: "app-pwa-prompt",
  standalone: true,
    imports: [CommonModule, MatToolbarModule, MatIconModule, MatButtonModule, TranslocoDirective],
  templateUrl: "./pwa-prompt.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  schemas: [NO_ERRORS_SCHEMA],
})
export class PwaPromptComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: "ios" | "android"; promptEvent?: any },
    private bottomSheetRef: MatBottomSheetRef<PwaPromptComponent>,
  ) {}

  public close() {
    this.bottomSheetRef.dismiss();
  }
}
