import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientService } from "./http-client.service";
import { HttpClientConfig } from "./http-client-config";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [HttpClientService],
  exports: [HttpClientModule],
})
export class CustomHttpClientModule {
  static forRoot(config: Partial<HttpClientConfig>): ModuleWithProviders<CustomHttpClientModule> {
    return {
      ngModule: CustomHttpClientModule,
      providers: [{ provide: HttpClientConfig, useValue: config }],
    };
  }
}
