import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DASHBOARD_ROUTE } from "app/const/app-constant";
import { MatButtonModule } from "@angular/material/button";
import { RouterLink } from "@angular/router";
import { LanguageService } from "app/language.service";
import { TranslocoDirective } from "@ngneat/transloco";

@Component({
  selector: "app-no-brain",
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterLink, TranslocoDirective],
  templateUrl: "./no-brain.component.html",
  styleUrls: ["./no-brain.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoBrainComponent implements OnInit {
  activeLang = "";
  @Input() isEmbeddedChat = true;
  protected readonly DASHBOARD_ROUTE = DASHBOARD_ROUTE;

  constructor(private languageService: LanguageService) {}
  ngOnInit(): void {
    this.languageService.activeLang$.subscribe((lang) => {
      this.activeLang = lang;
    });
  }
}
