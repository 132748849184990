import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { ValidationMessageService } from "./validation-message.service";

@Component({
  selector: "app-validation-message",
  templateUrl: "./validation-message.component.html",
  styleUrls: ["./validation-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent implements OnInit, DoCheck {
  @Input()
  public formControlVal!: AbstractControl;
  @Input()
  public labelText!: string;
  @Input()
  public marginTop?: number;
  @Input()
  /**
   * Can use error to override message
   * @example { password: "Invalid Password", required: "My custom message"}
   */
  public error?: Record<string, string>;
  public styleObj: unknown = {};
  public errorMessage?: string | null;

  constructor(private validation: ValidationMessageService, private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    if (!this.formControlVal) {
      throw new Error("Form Control cannot be null or undefined");
    }

    this.errorMessage = this.validation.getErrorMessage(this.formControlVal, this.labelText, this.error);
  }

  public ngDoCheck() {
    const olderErrorMessage = this.errorMessage;
    this.errorMessage = this.validation.getErrorMessage(this.formControlVal, this.labelText, this.error);
    if (this.errorMessage || olderErrorMessage) {
      this.cdr.markForCheck();
    }
  }
}
