import { formatDate } from "@angular/common";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidator {
  public email: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const regex = new RegExp(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/);

    return control.value.length > 4 && !regex.test(control.value) ? { email: true } : null;
  };

  public static phone(control: FormControl) {
    if (control.value) {
      const regex = new RegExp(/^\d{7,15}$/);
      if (!regex.test(control.value)) {
        return { phone: true };
      }
    }
    return null;
  }

  public static password(control: FormControl) {
    if (control.value) {
      // const regex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
      const regex = new RegExp(/[A-Za-z\d]{8,}/);
      if (!regex.test(control.value)) {
        return { password: true };
      }
    }
    return null;
  }

  public static maxNum(num: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (control.value) {
        if (parseFloat(control.value) !== control.value) {
          return { invalidNum: { num: control.value } };
        }

        if (parseFloat(control.value) > num) {
          return {
            maxNum: {
              requiredNumber: num,
              actualNumber: parseFloat(control.value),
            },
          };
        }
      }
      return null;
    };
  }

  public static minNum(num: number) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (control.value) {
        if (parseFloat(control.value) !== control.value) {
          return { invalidNum: { num: control.value } };
        }

        if (parseFloat(control.value) < num) {
          return {
            minNum: {
              requiredNumber: num,
              actualNumber: parseFloat(control.value),
            },
          };
        }
      }
      return null;
    };
  }

  public static isNumber(control: FormControl) {
    if (control.value) {
      const regex = new RegExp(/^[0-9]*$/);
      if (!regex.test(control.value)) {
        return { invalidNum: true };
      }
    }
    return null;
  }

  public static utf8AlphanumericValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null;
    }

    const alphanumericRegex = /^[\p{L}\p{N}'’]+$/u;
    const isValid = alphanumericRegex.test(value);

    return isValid ? null : { utf8Alphanumeric: true };
  }

  public static url() {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (control.value) {
        const regex = new RegExp(
            /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/i
        );
        if (!regex.test(control.value)) {
          return { invalidUrl: true };
        }
      }
      return null;
    };
  }


  public static phoneWithPlus(control: FormControl) {
    if (control.value) {
      const regex = new RegExp(/^([+]+[' ']+[0-9]{7,19})$/);
      if (!regex.test(control.value)) {
        return { phone: true };
      }
    }
    return null;
  }

  public static expiryDate(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const regex = new RegExp(/^(0[1-9]|1[0-2])\/?([0-9]{2,2})$/);
      const dd = new Date();
      const expiryYear = control.value.substring(3, 5);
      const expiryMonth = control.value.substring(0, 2);
      const expiryDateCheck = new Date(parseInt("20" + expiryYear), expiryMonth, dd.getDay());
      const futureDateCheck = new Date();
      futureDateCheck.setFullYear(dd.getFullYear() + 10);
      //check date must be greater than current date
      const futureDate =
        formatDate(expiryDateCheck, "yyyy-MM-dd", "en_US") > formatDate(new Date(), "yyyy-MM-dd", "en_US");
      //check date must not be greater than more than 10 years from today
      const futureMaxDateCheck =
        formatDate(expiryDateCheck, "yyyy-MM-dd", "en_US") < formatDate(futureDateCheck, "yyyy-MM-dd", "en_US");
      if (!regex.test(control.value) || !futureDate || !futureMaxDateCheck) {
        return { expirationDate: true };
      }
    }
    return null;
  }
}
