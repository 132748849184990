import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { NewVersionDialogComponent } from "app/core/components/new-version-dialog/new-version-dialog.component";
import { filter, firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CacheUpdateService {
  private readonly MIN_TIME_BETWEEN_CHECKS = 10000; // 10s
  private readonly SS_LAST_UPDATE = "lastUpdateCheck";
  private readonly OPTIONAL_UPDATE_ROUTES = ["/brain"]

  constructor(
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    ).subscribe((event) => {
      if (this.isOptionalUpdateRoute(event.urlAfterRedirects)) {
        this.optionalCheckForUpdate();
      } else {
        this.forceCheckForUpdates();
      }
    });

    this.swUpdate.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        if (this.isOptionalUpdateRoute(this.router.url)) {
          this.optionalCheckForUpdate();
        } else {
          this.forceCheckForUpdates();
        }
      } else if (event.type === 'NO_NEW_VERSION_DETECTED') {
      } else if (event.type === 'VERSION_INSTALLATION_FAILED') {
      }
    });
  }

  async forceCheckForUpdates(): Promise<void> {
    if (this.swUpdate.isEnabled) {
      try {
        if (!this.isCheckAllowed()) {
          return;
        }

        const updateAvailable = await this.swUpdate.checkForUpdate();
        this.updateLastCheckTime();

        if (updateAvailable) {
          await this.swUpdate.activateUpdate();
          window.location.reload();
        } else {
        }
      } catch (err) {
        console.error("Error during force update check", err);
      }
    }
  }

  private isCheckAllowed(): boolean {
    const lastCheck = sessionStorage.getItem(this.SS_LAST_UPDATE);
    if (!lastCheck) return true;

    const lastCheckTime = parseInt(lastCheck, 10);
    const timeBetween = Date.now() - lastCheckTime;

    return timeBetween >= this.MIN_TIME_BETWEEN_CHECKS;
  }

  private updateLastCheckTime(): void {
    const currentTime = Date.now().toString();
    sessionStorage.setItem(this.SS_LAST_UPDATE, currentTime);
  }

  async optionalCheckForUpdate(): Promise<void> {
    if (this.swUpdate.isEnabled) {
      try {
        const updateAvailable = await this.swUpdate.checkForUpdate();
        
        if (updateAvailable) {
          await this.showUpdateDialog();
        }
      } catch (err) {
        console.error("Error during optional update check:", err);
      }
    }
  }

  private async showUpdateDialog(): Promise<void> {
    const dialogRef = this.dialog.open(NewVersionDialogComponent, {
      panelClass: "update-dialog",
      position: { top: "2rem" },
      disableClose: false,
    });
    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result === true) {
      await this.swUpdate.activateUpdate();
      window.location.reload();
    } 
  }

  private isOptionalUpdateRoute(url: string): boolean {
    return this.OPTIONAL_UPDATE_ROUTES.some(route => url.startsWith(route));
  }
}
