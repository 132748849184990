import { Injectable } from "@angular/core";
import { environment } from "app/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChatBotService {
  showChatBot(src: string, dataId: string): void {
    document.body.classList.remove("hide-chatbot");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.setAttribute("data-id", dataId);
    document.head.appendChild(script);
  }

  hideChatBot(): void {
    document.body.classList.add("hide-chatbot");
    const existingScript = document.querySelector(
      `script[src="${environment.baseUrl}/assets/scripts/embed-chat-js-min.js"]`,
    );
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
  }
}
