import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "codeFormat",
})
export class CodeFormatPipe implements PipeTransform {
  transform(value: string) {
    let endIndex = value.indexOf("<");
    if (endIndex < 0) {
      endIndex = value.indexOf("\n");
    }
    const language = value.substring(0, endIndex);
    const languageName = language[0].toUpperCase() + language.slice(1);
    let newRecord = this.deleteStringBetweenIndices(value, 0, endIndex - 1);
    newRecord = newRecord.replace("```", "");
    return {
      languageName,
      code: newRecord,
    };
  }

  private deleteStringBetweenIndices(inputString: string, startIndex: number, endIndex: number) {
    if (startIndex < 0 || endIndex >= inputString.length || startIndex > endIndex) {
      // Handle invalid indices
      return inputString;
    }

    const leftPart = inputString.substring(0, startIndex);
    const rightPart = inputString.substring(endIndex + 1);

    return leftPart + rightPart;
  }
}
