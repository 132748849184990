import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { LANGUAGE_MAP } from "../const/app-constant";
import { LanguageService } from "../language.service";
declare let webkitSpeechRecognition: any;
@Injectable({
  providedIn: "root",
})
export class VoiceRecognitionService {
  recognition: any;
  isStoppedSpeechRecog = false;
  public text = "";
  private voiceToTextSubject: Subject<string> = new Subject();
  private speakingPaused: Subject<any> = new Subject();
  private tempWords = "";
  private languageSubscription: any;
  public activeLang = "";

  constructor(private languageService: LanguageService) {}

  /** Function to return observable so voice sample text can be send to input. */
  speechInput() {
    return this.voiceToTextSubject.asObservable();
  }

  /** Function to initialize voice recognition. */
  init() {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.interimResults = true;
    this.recognition.addEventListener("result", (e: any) => {
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join("");
      this.tempWords = transcript;
      this.voiceToTextSubject.next(this.text || transcript);
    });
    return this.initListeners();
  }

  /** Add event listeners to get the updated input and when stoped */
  initListeners() {
    this.recognition.addEventListener("end", () => {
      this.recognition.stop();
    });
    return this.speakingPaused.asObservable();
  }

  start(languageCode: string) {
    this.text = "";
    this.isStoppedSpeechRecog = false;
    const recognizedLanguageCode = LANGUAGE_MAP[languageCode as keyof typeof LANGUAGE_MAP] || "en-US";
    this.recognition.lang = recognizedLanguageCode;
    this.recognition.start();
    this.recognition.addEventListener("end", () => {
      this.stop();
      this.voiceToTextSubject.next(this.text);
    });
  }

  /** Function to stop recognition. */
  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat();
    this.recognition.stop();
    this.recognition.isActive = false;
    this.speakingPaused.next("Stopped speaking");
  }

  /** Merge previous input with latest input. */
  wordConcat() {
    this.text = this.text.trim() + " " + this.tempWords;
    this.text = this.tempWords?.trim()?.length ? this.tempWords : this.text;
    this.tempWords = "";
  }
}
