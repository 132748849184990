import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { BaseComponent } from "../base-component/base-component.component";

@Component({
  selector: "app-base-http",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./base-http.component.html",
  styleUrls: ["./base-http.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseHttpComponent extends BaseComponent {
  protected http = inject(HttpClientService);

}
