/* eslint-disable max-lines */
import { HttpClient, HttpContext, HttpErrorResponse, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppStore } from "app/app-store.service";
import { LS_REFRESH_TOKEN, LS_TOKEN } from "app/const/app-constant";
import { environment } from "app/environments/environment";
import { RefreshTokenResponse } from "app/pages/home/home.model";
import { Observable, catchError, map, mergeMap, of, retry, tap, throwError, timer } from "rxjs";
import { getLocalStorageInterface, isLocalStorageInterfaceDefined } from "../mobile-interfaces/app-local-storage-interface";
import { HttpClientConfig } from "./http-client-config";

@Injectable({
  providedIn: "root",
})
export class HttpClientService extends HttpClientConfig {
  constructor(
    private httpClient: HttpClient,
    private config: HttpClientConfig,
  ) {
    super();
    Object.keys(config).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)[key] = (config as any)[key];
    });
    this.handleRefreshTokenFlow = this.refreshToken.bind(this);
  }

  public get<T>(url: string, options?: HttpClientOptions) {
    return this.sendRequest<T>(url, HttpMethod.get, options);
  }

  public post<T>(url: string, body: unknown, options?: HttpClientOptions) {
    if (!options) {
      options = {} as HttpClientOptions;
    }
    options.body = body;
    return this.sendRequest<T>(url, HttpMethod.post, options);
  }

  public put<T>(url: string, body: unknown, options?: HttpClientOptions) {
    if (!options) {
      options = {} as HttpClientOptions;
    }
    options.body = body;
    return this.sendRequest<T>(url, HttpMethod.put, options);
  }

  public patch<T>(url: string, body: unknown, options?: HttpClientOptions) {
    if (!options) {
      options = {} as HttpClientOptions;
    }
    options.body = body;
    return this.sendRequest<T>(url, HttpMethod.patch, options);
  }

  private refreshToken(): Observable<ApiResponse<RefreshTokenResponse>> {
    const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN);
    if (!refreshToken) {
      return throwError(() => new Error("No refresh token available"));
    }
    return this.httpClient
      .post<RefreshTokenResponse>(
        `${environment.dotNetBaseUrl}/api/Authentication/refresh`,
        { refreshToken },
        { observe: "response" }
      )
      .pipe(
        map((res)=>{
          return this.getApiResponseObject(res);
        }),
        catchError((error: HttpErrorResponse) => {
          console.error("Refresh token error:", error);
          return throwError(() => error);
        }),
        tap((resp) => {

          if (resp.isSuccess && resp.data) {
            localStorage.setItem(LS_TOKEN, resp.data.accessToken);
            if (resp.data.refreshToken)
              localStorage.setItem(LS_REFRESH_TOKEN, resp.data.refreshToken);
          }

          if (isLocalStorageInterfaceDefined()) {
            getLocalStorageInterface().onAccessTokenDataSet(resp.data.accessToken);
            if (resp.data.refreshToken)
              getLocalStorageInterface().onRefreshTokenDataSet(resp.data.refreshToken);
          }
        }),

      );
  }

  public delete<T>(url: string, body?: unknown, options?: HttpClientOptions) {
    if (!options) {
      options = {} as HttpClientOptions;
    }
    options.body = body;
    return this.sendRequest<T>(url, HttpMethod.delete, options);
  }

  private setDefaultHttpClientOptions<T>(options: HttpClientOptions) {
    if (!this.onResponse && this.config.onResponse) {
      this.onResponse = this.config.onResponse;
    }

    if (!this.onRequest && this.config.onRequest) {
      this.onRequest = this.config.onRequest;
    }

    if (!options.headers) {
      options.headers = {};
    }

    if (this.config.defaultHeaders) {
      options.headers = { ...options.headers, ...this.defaultHeaders };
    }
    if (options.isAuth === undefined) {
      // change based on requirement
      options.isAuth = this.isAuthDefault;
    }
    if (!options.responseType) {
      options.responseType = "json";
    }

    if (options.showNotificationMessage === undefined) {
      options.showNotificationMessage = true;
    }

    if (options.showLoader === undefined) {
      options.showLoader = this.config.defaultShowLoader === undefined ? true : this.config.defaultShowLoader;
    }

    if (options.isAuth) {
      if (!this.getAuthToken) {
        throw new Error("Please set HttpClient.getAuthToken in your application");
      }
      const token = this.getAuthToken();
      if (token) {
        if (!this.getAuthHeader) {
          throw new Error("Please set HttpClient.getAuthHeader in your application");
        }
        options.headers = {
          ...options.headers,
          ...this.getAuthHeader(token),
        };
      } else {
        const apiResponse = getDefaultApiResponseObj<T>(HttpStatusCode.Unauthorized);
        if (this.onResponse) {
          this.onResponse(apiResponse, options);
        }
        return of(apiResponse as ApiResponse<T>);
      }
    }
    return options;
  }

  public sendRequest<T>(
    url: string,
    method: HttpMethod,
    options: HttpClientOptions = {} as HttpClientOptions,
  ): Observable<ApiResponse<T | undefined>> {
    const newOptions = this.setDefaultHttpClientOptions<T>(options);
    if (newOptions instanceof Observable) {
      return newOptions;
    } else {
      options = newOptions;
    }

    url = this.setUrl ? this.setUrl(url) : url;
    options.method = method;
    const maxRetryCount = options.maxRetryCount || this.maxRetryCount;

    if (this.onRequest) {
      this.onRequest(options);
    }
    return isOnline().pipe(
      mergeMap((status) => {
        if (!status) {
          const response = getDefaultApiResponseObj<null>(0);

          return throwError(() => response);
        }
        return of(status);
      }),
      // retry for max retry count if internet not available
      retry({
        count: maxRetryCount,
        delay: (_error, retryCount: number) => {
          if (retryCount === maxRetryCount) {
            return throwError(() => _error);
          }
          return timer(retryCount * this.retryTime);
        },
      }),
      mergeMap(() => {
        return this.httpClient.request(method, url, { ...options, observe: "response" }).pipe(
          mergeMap((response: HttpResponse<T>) => {
            // return response.body as ApiResponse<T>;
            const resp = this.handleResponse<T>(response, url, method, options);
            if (resp instanceof Observable) {
              return resp;
            }
            return of(resp);
          }),
          // this catch will execute When error in original request
          // if 401 status will come then handleErrorResponse will try to
          // regenerate token from refresh token
          catchError((err: HttpErrorResponse) => {
            const resp = this.handleErrorResponse<T>(err, url, method, options);
            if (resp instanceof Observable) {
              return resp;
            }
            return of(resp);
          }),
          // retry request if 5xx(server) error
          mergeMap((response) => {
            if (response.status.toString().startsWith("5")) {
              // throw error with current response to get back when retry will throw error
              return throwError(() => new Error(JSON.stringify(response)));
            }
            if (this.onResponse) {
              this.onResponse(response, options);
            }
            return of(response);
          }),
          // retry request for 5xx error
          retry({
            count: maxRetryCount,
            delay: (_error, retryCount: number) => {
              if (retryCount === maxRetryCount) {
                return throwError(() => _error);
              }
              return timer(retryCount * this.retryTime);
            },
          }),
          // catch if max retry reached
          catchError((err: Error) => {
            if (this.onResponse) {
              this.onResponse(JSON.parse(err.message), options);
            }
            return of(JSON.parse(err.message) as ApiResponse<T>);
          }),
        );
      }),
      catchError((err) => {
        console.error(err);
        // show toast message of internet not available
        const apiResponse: ApiResponse<null> = {
          status: 0,
          data: null,
          message: [this.internetNotAvailableMsg],
          errorCode: -1,
          isSuccess: false,
        };
        if (this.onResponse) {
          this.onResponse(apiResponse, options);
        }
        return of(apiResponse as ApiResponse<T>);
      }),
    );
  }

  /**
   * Get ApiResponse object
   *
   * @param response {@link AjaxResponse} response object of ajax request
   * @returns {@link ApiResponse}
   */
  private getApiResponseObject<T>(response: HttpResponse<T> | HttpErrorResponse) {
    if (!this.processMessage) {
      const msg = "Please set HttpClient.processMessage in your application";
      console.error(msg);
      throw new Error(msg);
    }
    const message = this.processMessage(response);
    let isError = false;
    if (!this.getStatusCode) {
      const msg = "Please set HttpClient.getStatusCode in your application";
      console.error(msg);
      throw new Error(msg);
    }
    const statusCode = this.getStatusCode(response);
    if (statusCode >= 400 || statusCode === 0) {
      isError = true;
    }

    if (!this.processData) {
      throw new Error("Please set HttpClient.processData in your application");
    }
    if (!this.getErrorCode) {
      throw new Error("Please set HttpClient.getErrorCode in your application");
    }

    const apiResponse: ApiResponse<T> = {
      status: statusCode,
      data: this.processData<T>(response),
      message,
      errorCode: this.getErrorCode(response),
      isSuccess: !isError,
    };
    return apiResponse;
  }

  private retryOriginalRequest<T>(url: string,
    method: HttpMethod,
    options: HttpClientOptions = {} as HttpClientOptions,): Observable<ApiResponse<T|undefined>> {

    const newOptions = {...options, maxRetryCount:1};

    // Resend the original request
    return this.sendRequest(url, method,newOptions);
  }



  private handleResponse<T>(response: HttpResponse<T>, url: string,
    method: HttpMethod,
    options: HttpClientOptions = {} as HttpClientOptions,) {
    const apiResponse = this.getApiResponseObject<T>(response);
    if (apiResponse.isSuccess) {
      return apiResponse;
    }
    // some api always send 200 status and follows a response structure
    // and sends actual response status in response body
    return this.handleErrorServerResponse(apiResponse, url,method, options);
  }

  private handleErrorResponse<T>(response: HttpResponse<T> | HttpErrorResponse, url: string,
    method: HttpMethod,
    options: HttpClientOptions = {} as HttpClientOptions,) {
    const apiResponse = this.getApiResponseObject<T>(response);
    return this.handleErrorServerResponse(apiResponse,url, method, options);
  }

  private handleErrorServerResponse<T>(apiResponse: ApiResponse<T | undefined>, url: string,
    method: HttpMethod,
    options: HttpClientOptions = {} as HttpClientOptions,) {
    if (apiResponse.status === 401) {
      if (!this.handleRefreshTokenFlow) {
        AppStore.logout$.next(null);
        return apiResponse;
      }

      const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN);

      if(!refreshToken){
        AppStore.logout$.next(null);
        return apiResponse;
      }

      // This code will execute when token is invalid
      return this.handleRefreshTokenFlow(options).pipe(
        mergeMap((res) => {
          if (res.status === undefined) {
            return throwError(() => new Error("handleRefreshTokenFlow should return object of ApiResponse"));
          }
          return this.retryOriginalRequest<T>(url, method, options);
        }),
      );
    } else {
      if (!options.sendResponseWhenError) {
        apiResponse.data = undefined;
      }
      return apiResponse;
    }
  }
}

export interface HttpClientOptions {
  /** Request Url */
  url?: string;
  /** Request Method */
  method?: HttpMethod;
  /** Request Body */
  body?: unknown;
  /** Request Headers */
  headers?: { [name: string]: string | string[] };
  /** Shared and mutable context that can be used by interceptors */
  context?: HttpContext;
  /**
   * Whether this request should be made in a way that exposes progress events.
   *
   * Progress events are expensive (change detection runs on each event) and so they should only be requested if the
   * consumer intends to monitor them.
   */
  reportProgress?: boolean;
  /** Whether this request should be sent with outgoing credentials (cookies). */
  withCredentials?: boolean;

  /**
   * The expected response type of the server.
   *
   * This is used to parse the response appropriately before returning it to the requested.
   */
  responseType?: "arraybuffer" | "blob" | "json" | "text";

  /**
   * Request is authenticated If true Authorization header will send
   *
   * @default true
   */

  isAuth?: boolean;
  /**
   * Send response when error in case when component need error response
   *
   * @default false
   */
  sendResponseWhenError?: boolean;
  /**
   * Max Retry for api if status 500 or network not available
   *
   * @default 3
   */
  maxRetryCount?: number;

  /**
   * Show toast/snackbar when error
   *
   * @default true
   */
  showNotificationMessage?: boolean;
  /** Extra used by service worker while caching. service worker send back this extra via postMessage */
  extra?: string;
  /**
   * By default caching from service worker will disable for all api request To enable caching for specific api set this
   * option to false
   */
  doCache?: boolean;
  /**
   * Show spinner/loader on api request
   *
   * @default true
   */
  showLoader?: boolean;
}

export function getDefaultApiResponseObj<T>(status: HttpStatusCode | 0, data?: T) {
  let isError = false;
  if (status >= HttpStatusCode.BadRequest || status === 0) {
    isError = true;
  }

  const response: ApiResponse<T | undefined> = {
    status: status,
    data: data,
    message: [],
    errorCode: -1,
    isSuccess: !isError,
  };
  return response;
}

/** Custom Response converted by HttpClient for ease of frontend development */
export interface ApiResponse<T> {
  /**
   * Status code of response If server api response will send status in response body as key status then HttpClient will
   * use response body status otherwise response status will use
   */
  status: number;
  /**
   * Response data of server HttpClient will convert api response into ApiResponse If API will send data key in response
   * body as key then HttpClient will use response body data otherwise HttpClient will put response body in
   * {@link ApiResponse.data}
   */
  data: T;
  /** Message in case of success and error Error message can be multiple in case of validation of form */
  message: string[];
  /**
   * Error Code. Some api sends error code. Error code helps in logging and also helps in multi language to show message
   * based on error code If API will not send errorCode then default -1 value will return
   *
   * @default -1
   */
  errorCode: number | string;
  /** Response will available only in case of jest test Don't use in service/component. It will always be undefined */
  response?: HttpResponse<T> | HttpErrorResponse;
  /**
   * Can use to check response was success or error HttpClient will set true only in case of status code 4xx, 5xx, no
   * internet available or status code 600 (client error)
   */
  isSuccess: boolean;
}

/**
 * IsOnline returns online status (connected to internet) of user on client side
 *
 * @returns Observable<boolean>
 */
export function isOnline() {
  return of(navigator.onLine);
}

export enum HttpMethod {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
  patch = "PATCH",
}
