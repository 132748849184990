import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { PwaPromptComponent } from "app/core/components/pwa-prompt/pwa-prompt.component";

@Injectable({
  providedIn: "any",
})
export class PwaService {
  private promptEvent: any;
  isAppInstalled = true;
  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform,
  ) {
    //event is triggered only when app not installed
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault(); //don't show default install app button
      if(event) {
        this.promptEvent = event;
        this.isAppInstalled = false;
      }
    });
    // detects only when installation occured. Doesn't tell if PWA was installed previously
    window.addEventListener('appinstalled', () => {
      this.isAppInstalled = true;
    });
  }

  initPwaPrompt() {
    if (this.platform.ANDROID) {
        if(this.promptEvent){
            this.promptEvent.prompt();
        }
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = "standalone" in window.navigator && window.navigator["standalone"];
      if (!isInStandaloneMode) {
        this.openPromptComponent("ios");
      }
    }
  }
  
  openPromptComponent(mobileType: "ios" | "android") {
    this.bottomSheet.open(PwaPromptComponent, { data: { mobileType, promptEvent: this.promptEvent } });
  }
}
