import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "app-confirm-dialog",
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslocoModule],
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  ConfirmDialogOption = ConfirmDialogOption;
  confirmBodyTxt!: string;
  okButtonTxt!: string;
  cancelButtonTxt!: string;
  title!: string;
}

export enum ConfirmDialogOption {
  cancel,
  ok,
}
