import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "app/environments/environment";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
  ) {
    if (environment.googleAnalytics) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: "page",
            pageName: item.url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }
}
