export interface ChatQueryResponse {
  answer: string;
  finalPrompt: string;
  dataReferences: Reference[];
  references?: string[];
  assistantImageIds?: string[];
  id: string;
  imageUrls?: string[];
  document_generated_response: boolean;
  metadata: unknown;
}

export interface Reference {
  author: string;
  content: string;
  img: string;
  link: string;
  score: number;
  section: string;
  time_stamp: number;
  title: string;
}

export enum ChatDataRole {
  user = "user",
  assistant = "assistant",
}

export interface SuggestedQuestionTranslation {
  data: {
    translations: {
      translatedText: string;
      detectedSourceLanguage: string;
    }[];
  };
}

export interface BrainChat {
  brainId: string;
  inProgress: boolean;
}

interface AssistantSpecialResponse {
  key: string;
  translationKey: string;
}

export const AssistantSpecialResponses: AssistantSpecialResponse[] = [
  { key: "<would_you_like_to_search_the_internet>", translationKey: "wouldYouLikeToSearchTheInternet" },
  { key: "<i_dont_know_the_answer>", translationKey: "iDontknowTheAnswer" },
  { key: "<acknowledged_lets_continue_our_conversation>", translationKey: "acknowledgedLetsContinueOurConversation" },
];

export function GetAssistantResponse(key: string) {
  return AssistantSpecialResponses.find((x) => x.key == key);
}

export interface SuggestedQuestionTranslation {
  data: {
    translations: {
      translatedText: string;
      detectedSourceLanguage: string;
    }[];
  };
}

interface AssistantSpecialResponse {
  key: string;
  translationKey: string;
}

export interface BrainChat {
  brainId: string;
  inProgress: boolean;
}

export const VALID_BRAIN_LLMS = [
  "azure/gpt-3.5-turbo",
  "azure/gpt-4o",
  "azure/gpt-4-turbo",
  "openai/gpt-4o",
  "openai/gpt-4-turbo",
  "gemini/gemini-1.5-flash",
  "gemini/gemini-1.5-pro",
  "azure_ai/mistral-small",
  "azure_ai/mistral-large",
  "anthropic/claude-3-haiku",
  "anthropic/claude-3-5-sonnet",
  "anthropic/claude-3-opus",
  "azure_ai/command-r",
  "azure_ai/command-r-plus",
  "azure_ai/llama-3.1-70b",
  "azure_ai/llama-3.1-405b",
  "azure_ai/phi-3.5-moe",
  "azure_ai/phi-3-medium"
];

export type BrainLLM = typeof VALID_BRAIN_LLMS[number];

export const defaultBrainLLM:BrainLLM = "openai/gpt-4o";
