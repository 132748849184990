interface DeviceAssistantInterface {
    onSettingsClick() : void;
    onCameraOpen() : void;
}

export function isMobileSettingsInterfaceDefined() {
    return typeof mobileappSettingsInterface !== "undefined";
}

export function getMobileappSettingsInterface() {
    return mobileappSettingsInterface;
}

declare let mobileappSettingsInterface: DeviceAssistantInterface; // interface is defined by the mobile apps
