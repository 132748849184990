import { Injectable } from "@angular/core";
import { AppStore } from "app/app-store.service";
import { AUDIO_ENCODING, LANGUAGE_MAP, SAMPLE_RATE_HERTZ } from "app/const/app-constant";
import { environment } from "app/environments/environment";
import { BehaviorSubject } from "rxjs";
import { Socket, io } from "socket.io-client";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  public message$: BehaviorSubject<string> = new BehaviorSubject("");
  public socket: Socket | undefined = undefined;
  bufferSize = 2048;
  globalStream: any = null;
  processor: any = null;
  input: MediaStreamAudioSourceNode | undefined = undefined;
  context: AudioContext | undefined = undefined;
  audioContext: unknown = undefined;
  mediaConstraints = {
    audio: true,
    video: false,
  };

  public connectSocket() {
    this.socket = io(environment.socketUrl, {
      transports: ["websocket"],
    });
    console.log("Speech service connected...");
  }

  public initRecording(transcribeConfig: any, onData: any, onError: any): void {
    this.socket?.emit("startGoogleCloudStream", { ...transcribeConfig });
    this.audioContext = window.AudioContext || (window as any).webkitAudioContext;
    this.context = new AudioContext();
    this.processor = this.context.createScriptProcessor(this.bufferSize, 1, 1);
    this.processor.connect(this.context.destination);
    this.context.resume();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const handleSuccess = function (stream: any) {
      self.globalStream = stream;
      self.input = self.context?.createMediaStreamSource(stream);
      self.input?.connect(self.processor);
      self.processor.onaudioprocess = function (e: any) {
        self.microphoneProcess(e);
      };
    };

    navigator.mediaDevices.getUserMedia(this.mediaConstraints).then(handleSuccess);

    if (onData) {
      this.socket?.on("speechData", (response) => {
        onData(response.data, response.isFinal);
      });
    }

    this.socket?.on("googleCloudStreamError", (error) => {
      if (onError) {
        onError("error");
      }
      this.closeAll();
    });

    this.socket?.on("endGoogleCloudStream", () => {
      this.closeAll();
    });
  }

  closeAll() {
    // Clear the listeners (prevents issue if opening and closing repeatedly)
    this.socket?.off("speechData");
    this.socket?.off("googleCloudStreamError");
    const tracks = this.globalStream ? this.globalStream.getTracks() : null;
    const track = tracks ? tracks[0] : null;
    if (track) {
      track.stop();
    }

    if (this.processor) {
      if (this.input) {
        try {
          this.input.disconnect(this.processor);
        } catch (error) {
          console.warn("Attempt to disconnect input failed.");
        }
      }
      this.processor.disconnect(this.context?.destination);
    }
    if (this.context) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.context.close().then(function () {
        self.input = undefined;
        self.processor = null;
        self.context = undefined;
        self.audioContext = null;
      });
    }
  }

  microphoneProcess(e: any) {
    const left = e.inputBuffer.getChannelData(0);
    const left16 = this.convertFloat32ToInt16(left);
    this.socket?.emit("binaryAudioData", left16);
  }

  convertFloat32ToInt16(buffer: any) {
    let l = buffer.length;
    const buf = new Int16Array(l / 3);

    while (l--) {
      if (l % 3 === 0) {
        buf[l / 3] = buffer[l] * 0xffff;
      }
    }
    return buf.buffer;
  }

  stopRecording() {
    this.socket?.emit("endGoogleCloudStream");
    this.closeAll();
  }

  getTranscriptionConfig() {
    const selectedLanguageIndex = AppStore.selectedLanguage$.value as keyof typeof LANGUAGE_MAP;
    const selectedLanguageCode = LANGUAGE_MAP[selectedLanguageIndex];
    return {
      audio: {
        encoding: AUDIO_ENCODING,
        sampleRateHertz: SAMPLE_RATE_HERTZ,
        languageCode: selectedLanguageCode || "en-US",
      },
      interimResults: true,
    };
  }
}
