import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { LS_TOKEN } from "app/const/app-constant";
import { environment } from "app/environments/environment";
import { removeSpaceFromKey } from "../functions/remove-space-from-key";
import { HttpClientConfig } from "../modules/http-client/http-client-config";

function getApiResponseBody(response: HttpResponse<unknown> | HttpErrorResponse) {
  let data;
  if (response instanceof HttpResponse) {
    data = response.body;
  } else {
    data = response.error;
  }
  return data;
}

export const httpClientConfig = new HttpClientConfig();
httpClientConfig.isAuthDefault = true;

const processMessage = (response: HttpResponse<unknown> | HttpErrorResponse) => {
  const body = getApiResponseBody(response);
  let message: string[] = [];
  const status: number = getStatusCode(response);

  if(!body){
    return message;
  }

  if (status >= 400) {
    const errorMessage = body?.body?.message || body.message || body.response?.message;
    if (typeof errorMessage === "string") {
      message.push(errorMessage);
    } else if (Array.isArray(errorMessage) && typeof errorMessage[0] === "string") {
      message = errorMessage;
    }
  }
  return message;
};

httpClientConfig.processMessage = processMessage;

const getStatusCode = (response: HttpResponse<unknown> | HttpErrorResponse) => {
  const body = getApiResponseBody(response);
  return (body?.response?.status as number) || response.status;
};

httpClientConfig.getStatusCode = getStatusCode;

/**
 * Replace this function body with your own code if api return different type of response While replacing remember that
 * this function will get called for success as well as error response
 *
 * @param response AjaxResponse<any> | AjaxError
 * @returns Api Response
 */
httpClientConfig.processData = <T>(response: HttpResponse<unknown> | HttpErrorResponse) => {
  const body = getApiResponseBody(response);
  const statusCode = getStatusCode(response);

  if (!body) {
    return {} as T;
  }

  // [TODO] support responseType
  if (statusCode >= 400) {
    return removeSpaceFromKey(body.body || body.response || body.data || body || {}) as T;
  }
  // can check instanceOf to know response type
  // some api send data in response and data field contain actual data
  return removeSpaceFromKey(body.response || body.data || body) as T;
};

httpClientConfig.getErrorCode = (response: HttpResponse<unknown> | HttpErrorResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const body = getApiResponseBody(response);
  return body?.response?.errorCode || -1;
};

httpClientConfig.getAuthToken = () => {
  const token = localStorage.getItem(LS_TOKEN);
  return token;
};

httpClientConfig.getAuthHeader = (token) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

httpClientConfig.internetNotAvailableMsg = "error.internetNotAvailable";

httpClientConfig.setUrl = (url) => {
  // pass absolute url to send request other than instant api
  let isAbsoluteUrl = false;
  try {
    new URL(url);
    isAbsoluteUrl = true;
  } catch {
    isAbsoluteUrl = false;
  }

  return isAbsoluteUrl ? url : `${environment.dotNetBaseUrl}${url}`;
};

httpClientConfig.defaultShowLoader = false;
