import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
  selector: "[brainLimitIndicator]",
  standalone: true,
})
export class BrainLimitDirective implements OnChanges {
  @Input() limitConfig: {
    isQuestionExceeded?: boolean;
    isContentExceeded?: boolean;
  } = {
    isQuestionExceeded: false,
    isContentExceeded: false,
  };

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["limitConfig"]) {
      this.updateLimitReachedClass();
    }
  }

  private updateLimitReachedClass() {
    if (this.limitConfig.isQuestionExceeded || this.limitConfig.isContentExceeded) {
      this.el.nativeElement.classList.add("limit-reached");
    } else {
      this.el.nativeElement.classList.remove("limit-reached");
    }
  }
}
