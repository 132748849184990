import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BaseComponent } from "../base-component/base-component.component";
import { BrainStyle } from "app/core/components/embedded-chat/embedded-chat.model";
import { addAlpha } from "app/core/functions/helper-functions";

@Component({
  selector: "app-follow-up-question",
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: "./follow-up-question.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowUpQuestionComponent extends BaseComponent implements OnInit {
  @Input() index: number | undefined = undefined;
  @Input() followUpQuestion = "";
  @Output() questionAsked = new EventEmitter<string>();
  @Input() customizeColors? = false;
  @Input() customizeColorValues?: BrainStyle;

  constructor(public translocoService: TranslocoService) {
    super();
  }

  ngOnInit() {
    this.subs$.add(
      AppStore.selectedLanguage$.subscribe((language) => {
        this.translocoService
          .selectTranslateObject("defaultFollowUpQuestion", {}, language || "en_gb")
          .subscribe((result) => {
            switch (this.index) {
              case 0:
                this.followUpQuestion = result.first;
                break;
              case 1:
                this.followUpQuestion = result.second;
                break;
              case 2:
                this.followUpQuestion = result.third;
                break;
            }
            this.cdr.markForCheck();
          });
      }),
    );
  }

  onClick() {
    this.questionAsked.emit(this.followUpQuestion);
  }

  protected readonly AppStore = AppStore;
  protected readonly addAlpha = addAlpha;
}
