import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class ValidationMessageService {
  constructor(private translocoService: TranslocoService) {}

  public messages: Record<string, string> = {
    required: "{labelText} is required",
    email: "Email is not valid",
    phone: "Phone number is not valid",
    invalidCard: "Card is not valid",
    maxlength: "{labelText} can be length of max {requiredLength}, Current Length {actualLength}",
    minlength: "{labelText} can be min length of {requiredLength}",
    max: "{labelText} can be a maximum of {max}",
    min: "{labelText} can be a minimum of {min}",
    expirationDate: "Please enter a valid date in the format mm/yy",
    fullName: "Full Name must have first name and last name",
    latLong: "Invalid Coordinates. Example of valid coordinates 28.612367651050764, 77.22959240652854",
    password: "{labelText} must at least 8 characters",
    strongPassword:
      "{labelText} must at least 8 characters with one alphabet, one number and one special character @$!%*#?&",
    emailOrPhone: "Email or Phone Number is not valid",
    invalidUrl: "Url is not valid",
  };

  public getErrorMessage(control: AbstractControl, labelText: string, error?: Record<string, string>): string | null {
    const errorKey = this.getErrorKey(control);
    if (errorKey && control.errors) {
      const errorMessage = (error && error[errorKey]) || this.getError(errorKey);
      if (errorMessage) {
        const errorObj = control.errors[errorKey];
        let placeholderObj = { labelText };
        if (typeof errorObj === "object") {
          placeholderObj = Object.assign({ labelText }, errorObj);
        }
        return this.setErrorMessageWithPlaceholder(errorMessage, placeholderObj);
      }
    }
    return null;
  }

  public getErrorKey(control: AbstractControl) {
    if (control.touched && control.errors) {
      const objectKeys = Object.keys(control.errors);
      if (objectKeys.length) {
        return objectKeys[0];
      }
    }
    return null;
  }

  private setErrorMessageWithPlaceholder(errorMessage: string, placeholderObj: Record<string, string>) {
    Object.keys(placeholderObj).forEach((key) => {
      const pattern = new RegExp(`({${key}})`);
      errorMessage = errorMessage.replace(pattern, placeholderObj[key]);
    });
    return errorMessage;
  }

  private getError(errorKey: string) {
    return this.translocoService.translate(`validation.${errorKey}`);
  }
}
