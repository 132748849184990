import { CommonModule, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoModule } from "@ngneat/transloco";
import { BrainContentType, EmbeddingFile, EmbeddingFileStatus } from "app/app.model";
import { BaseComponent } from "app/core/components/base-component/base-component.component";
import { getFileIconPath } from "app/core/functions/helper-functions";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "app-added-brain-content",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatTooltipModule,
  ],
  templateUrl: "./added-brain-content.component.html",
  styleUrls: ["./added-brain-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddedBrainContentComponent extends BaseComponent {
  @Input() embeddingFile!: EmbeddingFile;
  @Output() deleteContent = new EventEmitter<EmbeddingFile>();

  constructor() {
    super();
  }

  deleteContentData() {
    this.deleteContent.emit(this.embeddingFile);
  }

  protected readonly EmbeddingFileStatus = EmbeddingFileStatus;
  protected readonly BrainContentType = BrainContentType;
  getFileIconPath = getFileIconPath;
}
