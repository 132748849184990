import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslocoDirective } from "@ngneat/transloco";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-new-version-dialog",
  standalone: true,
  imports: [CommonModule, TranslocoDirective, MatDialogModule, MatButtonModule],
  templateUrl: "./new-version-dialog.component.html",
  styleUrls: ["./new-version-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewVersionDialogComponent {}
