<div class="flex flex-row gap-4 justify-center items-center" *transloco="let t">
  <div class="bg-[#8E78B51F] bg-opacity-[12%] w-9 h-9 rounded-full flex justify-center items-center">
    <img src="./assets/images/icons/info-icon.svg" class="w-5 h-5" />
  </div>
  <div>
    <div class="text-base text-black-dark">
      {{ t("pwa.newVersionTitle") }}
    </div>
  </div>
  <mat-dialog-actions class="!p-0 !min-h-fit">
    <button mat-button class="!bg-[#554176]" [mat-dialog-close]="true">
      <span class="font-semibold cursor-pointer text-white">{{ t("pwa.refresh") }}</span>
    </button>
  </mat-dialog-actions>
</div>
