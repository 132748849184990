import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "BrainCardUrlPipe",
})
export class BrainCardUrlPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (value === undefined || value === null) {
      return "";
    }
    return value.replace(/^https?:\/\//, "").replace(/^www\./, "");
  }
}
