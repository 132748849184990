import { isAppleApp } from "./app-initialization-interface";

interface WebAppMicrophoneInterface {
    notifyMobileAppForMicPermission() : void;
}

export function isMicrophoneInterfaceDefined() {
    return typeof mobileappMicrophoneInterface !== "undefined";
}

export function getMobileappMicrophoneInterface() {
    return mobileappMicrophoneInterface;
}

export function postAppleMicrophonePermission() {
    if (isAppleApp()) {
        window.webkit?.messageHandlers.default.postMessage({ "message": "Microphone" });
    }
}

declare let mobileappMicrophoneInterface: WebAppMicrophoneInterface; // interface is defined by the mobile apps
