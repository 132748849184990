import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiResponse, HttpClientOptions } from "./http-client.service";

export class HttpClientConfig {
  /**
   * Set maxRetryCount to retry HttpClient to defined number of times HttpClient retry request in case of internet not
   * available or api will respond 5xx status
   */
  public maxRetryCount = 3;
  /** Set retryTime when HttpClient will retry */
  public retryTime = 1000;
  /** HttpClient uses isAuthDefault to set default value for {@link HttpClientOptions.isAuth} */
  public isAuthDefault = false;
  public internetNotAvailableMsg = "Please check your network connection. Internet not available";

  /**
   * Implement this function to return success and error message from api response HttpClient will call this function
   * after getting response from api to create ApiResponse object
   */
  public processMessage?: (response: HttpResponse<unknown> | HttpErrorResponse) => string[];
  /**
   * Implement this function to return data from api response HttpClient will call this function after getting response
   * from api to create ApiResponse object
   */
  public processData?: <T>(response: HttpResponse<T> | HttpErrorResponse) => T;
  /**
   * Implement this function to return code from api response HttpClient will call this function after getting response
   * from api to create ApiResponse object
   */
  public getErrorCode?: (response: HttpResponse<unknown> | HttpErrorResponse) => number;
  /**
   * Implement this function to return status code from api response HttpClient will call this function after getting
   * response from api to create ApiResponse object
   */
  public getStatusCode?: (response: HttpResponse<unknown> | HttpErrorResponse) => number;
  /** HttpClient will call this function just before return ApiResponse */
  public onResponse?: <T>(apiResponse: ApiResponse<T>, options: HttpClientOptions) => void;
  /** HttpClient will call this function just before return ApiResponse */
  public onRequest?: (options: HttpClientOptions) => void;

  /**
   * HttpClient calls this function to get Auth token return empty string in case token not available in case of empty
   * string HttpClient will send ApiResponse with 401 status
   */
  public getAuthToken?: () => string | null;
  /**
   * HttpClient calls this function when {@link HttpClientOptions.isAuth} sets to true this function must return
   * authentication header
   */
  public getAuthHeader?: (token: string) => RequestHeader;
  /**
   * HttpClient calls this function when api server respond with 401 status code HttpClient calls this function to
   * regenerate token If this function will return success then HttpClient will call api again with new token
   */
  public handleRefreshTokenFlow?: (options: HttpClientOptions) => Observable<ApiResponse<unknown>>;

  /**
   * HttpClient call this function to set url of request You can use this function to set domain based on env variable
   * for every request
   */
  public setUrl?: (url: string) => string;

  /** Default Headers to send in every request */
  public defaultHeaders?: RequestHeader;

  /**
   * Default value for showLoader option of HttpClientOptions
   *
   * @default true
   */
  public defaultShowLoader?: boolean;
}

export type RequestHeader = { [key: string]: string | string[] };
